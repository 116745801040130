var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-lg-8"},[_c('h2',[_vm._v("Current Ticket")]),_c('v-card',{staticClass:"d-flex justify-space-between",attrs:{"flat":""}},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item",on:{"click":_vm.backToList}},[_vm._v("Tickets")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Selected Tickets")])])])]),_c('v-card-actions',{staticClass:"d-flex justify-end"},[(_vm.getUserTicket.resolve < 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.resolveTicket(_vm.getUserTicket.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,false,2040857798)},[_c('span',[_vm._v("Resolve ticket")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}])},[_c('span',[_vm._v("Pending")])])],1),_c('v-card',[_c('v-list-item',[_c('v-list-item-title',[_c('v-icon',[_vm._v(" mdi-bug ")]),_vm._v(" "+_vm._s(_vm.getUserTicket.issue)+" ")],1),_c('v-list-item-title',[_vm._v(" type of issue: "+_vm._s(_vm.getUserTicket.issue_type)+" ")]),_c('v-list-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":""},on:{"click":function($event){return _vm.viewMessages(_vm.getUserTicket.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-message-reply ")])]}}])},[_c('span',[_vm._v("Send message")])])],1)],1)],1),_c('v-card',[_c('v-card-text',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.getUserTicket.issue)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }